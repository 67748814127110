import Loading from '@/components/loading';
import { paperShadow } from '@/providers/theme/themes';
import { Box, Grid, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Script from 'next/script';
import { Fragment, useState } from 'react';
import { useAsyncEffect } from 'rooks';

export default function CloverCard( { gateway, clover } ) {
	const [ Clover, setClover ] = useState<any>( () => window.Clover );
	const [ isLoading, setIsLoading ] = useState( true );
	const theme = useTheme();
	const queryClient = useQueryClient();
	
	useAsyncEffect( async () => {
		if ( !Clover || !gateway ) return;
		const styles = {
			'input': {
				'color'           : theme.palette.text.primary,
				'background-color': 'transparent',
				
			},
			'section': {
				'border'          : `1px solid ${theme.palette.divider}`,
				'border-radius'   : 5,
				'padding'         : 10,
				'background-color': theme.palette.background.default,
				'color'           : theme.palette.text.primary,
				'borderRadius'    : '7px',
				'boxShadow'       : theme.shadows[ paperShadow as any ],
			},
			'.brand': {
				'top'       : 8,
				'right'     : 8,
				'max-height': 25,
			},
		};
		
		let dataAccessKey = gateway?.cloverApiAccessKey;
		
		if ( !dataAccessKey ) {
			try {
				const { data } = await axios.get( `/api/processor/clover_pakms?id=${gateway.id}` );
				if ( data?.apiAccessKey ) {
					dataAccessKey = data.apiAccessKey;
					await queryClient.invalidateQueries( [ 'order' ] );
				}
			} catch {
			}
		}
		clover.current = new Clover( dataAccessKey );
		
		const elements = clover.current.elements();
		
		const cardNumber = elements.create( 'CARD_NUMBER', styles );
		const cardDate = elements.create( 'CARD_DATE', styles );
		const cardCvv = elements.create( 'CARD_CVV', styles );
		const cardPostalCode = elements.create( 'CARD_POSTAL_CODE', styles );
		
		try {
			await Promise.all( [
				cardNumber.mount( '#card-number' ),
				cardDate.mount( '#card-date' ),
				cardCvv.mount( '#card-cvv' ),
				cardPostalCode.mount( '#card-postal-code' ),
			] );
			setIsLoading( false );
		} catch ( error ) {
			console.error( 'Error mounting elements:', error );
		}
	}, [ Clover, gateway ] );
	return (
		<Fragment>
			<Script
				src={`https://${process.env.NEXT_PUBLIC_DEV ? 'checkout.sandbox.dev' : 'checkout'}.clover.com/sdk.js`}
				onLoad={() => setClover( () => window.Clover )}
			/>
			{isLoading && <Loading/>}
			<Box sx={{ display: isLoading ? 'hidden' : 'block' }}>
				<Grid container spacing={1} sx={{ height: 100 }}>
					<Grid item xs={12} height={2}>
						<div id='card-number'/>
					</Grid>
					<Grid item xs={4} height={2}>
						<div id='card-date'/>
					</Grid>
					<Grid item xs={4} height={2}>
						<div id='card-cvv'/>
					</Grid>
					<Grid item xs={4} height={2}>
						<div id='card-postal-code'/>
					</Grid>
				</Grid>
			</Box>
		</Fragment>
	);
}
