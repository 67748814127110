import { menuDarkModeBoxShadow, popOverShadow } from '@/providers/theme/themes';
import { colors, Grow, Tooltip, useTheme } from '@mui/material';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { CustomTooltipProps } from './types';

export default function CustomTooltip( {
	stayOpen,
	toolTipChildren,
	...muiTooltipProps
}: CustomTooltipProps ) {
	const [ open, setOpen ] = useState( stayOpen || false );
	const theme = useTheme();
	const isDarkMode = theme.palette.mode === 'dark';
	
	const handleTitle = typeof toolTipChildren === 'function'
		? toolTipChildren( () => setOpen( false ) )
		: toolTipChildren;
	
	return (
		<Tooltip
			TransitionComponent={Grow}
			open={open}
			title={handleTitle}
			onOpen={() => {
				if ( stayOpen ) return;
				setOpen( true );
			}}
			onClose={( event: Event | SyntheticEvent<Element, Event> ) => {
				if ( stayOpen ) return;
				
				if ( event.type === 'mouseleave' ) {
					setOpen( false );
				}
			}}
			{...muiTooltipProps}
			componentsProps={{
				tooltip: {
					sx: {
						'border'      : theme.palette.mode === 'dark' ? `1px solid ${theme.palette.divider}` : undefined,
						// overflow: 'hidden', // adding this causes the tooltip arrow to not show up
						'borderRadius': 2,
						'boxShadow'   : !isDarkMode
							? theme.shadows[ popOverShadow as any ]
							: menuDarkModeBoxShadow,
						'& .MuiTooltip-arrow': {
							':before': {
								border: `1px solid ${theme.palette.divider}`,
							},
							'fontSize': 15,
							'color'   : theme.palette.mode === 'dark'
								? colors.grey[ 800 ]
								: theme.palette.background.default,
							'maxWidth': 'unset !important',
						},
						...muiTooltipProps.sx,
					},
				},
			}}
		/>
	);
}
