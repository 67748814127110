import StyledImage from './styledImage';

export default function CreditCardBrandImage( { brand, type }: { brand: string, type?: string } ) {
	
	const brandImageMap = {
		VISA           : 'visa.svg',
		MASTERCARD     : 'mastercard.svg',
		AMEX           : 'amex.svg',
		DISCOVER       : 'discover.svg',
		DINERS_CLUB    : 'jcb.svg',
		MC             : 'mastercard.svg',
		JCB            : 'jcb.svg',
		AMERICANEXPRESS: 'amex.svg',
	};
	
	const typeImageMap = {
		cash : 'cash-icon.svg',
		check: 'bank-cheque.svg',
	};
	
	let src;
	if ( type ) {
		src = typeImageMap[ type.toLowerCase() ];
	}
	if ( !src ) {
		src = brandImageMap[ brand ] || 'unknown.svg';
	}
	
	return (
		<StyledImage
			sx={{ width: 25 }}
			src={`${process.env.NEXT_PUBLIC_SITE_URL}/images/brand/${src}`}
			alt={brand}
		/>
	);
}
